<template>
  <header class="header">
    <div class="header__box" :class="{'header__box--only-logo': !isAuth}">
      <div class="header__logo">
        <img src="@/assets/logo.svg" class="header__logo-img" />
        <span>Karma</span>
      </div>
      <div class="header__data" v-if="isAuth">
        <div class="header__user">
          <Avatar :imgSrc="currentUser.photo_url" />
          <span class="header__user-name">{{ currentUser.full_name }}</span>
        </div>
        <LocaleMenu />
        <button class="header__logout" @click="logOut">
          {{ $t('auth.logout') }}
        </button>
      </div>
    </div>
  </header>
</template>

<script setup>
import { Avatar } from '@sliday/karma-ui-library';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import LocaleMenu from './LocaleMenu.vue';

const store = useStore();
const router = useRouter();

const isAuth = computed(() => {
  return store.state.auth.isLoggedIn;
});

const currentUser = computed(() => {
  return store.state.auth.currentUser;
});

const logOut = async () => {
  await store.dispatch('auth/logout');
  router.push({name: 'Login'})
}
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  background: #fff;
  box-shadow:
    0px 8px 60px 0px rgba(35, 75, 111, 0.04),
    0px 8px 40px 0px rgba(75, 111, 143, 0.15),
    0px 6px 10px 0px rgba(117, 145, 171, 0.02);
  padding: 7px 15px;

  &__box {
    max-width: 1140px;
    margin: 0 auto;
    min-height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--only-logo {
      justify-content: center;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    gap: 4px;
    font-weight: 600;
  }

  &__logo-img {
    object-fit: contain;
    width: 22px;
    height: 22px;
    border-radius: 8px;
  }

  &__data {
    display: flex;
    gap: 30px;
    align-items: center;
  }

  &__user {
    display: flex;
    align-items: center;
    gap: 10px;

    @include sm {
      display: none;
    }
  }

  &__user-avatar {
    width: 36px;
    height: 36px;
    border-radius: 1000px;
  }

  &__user-name {
    color: $dark;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
  }

  &__logout {
    background-color: transparent;
    cursor: pointer;
    color: $dark;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; 
  }

}
</style>
