import { ONBOARDING_COOKIE } from '@/constants/cookies';
import api from '../plugins/axios';

function handleError(error) {
  console.error('API Error:', error);
}

export default {
  state: {
    currentUser: {},
    token: null,
    isLoggedIn: false,
  },
  mutations: {
    setLoggedOut(state) {
      state.currentUser = {};
      state.token = null;
      state.isLoggedIn = false;
      localStorage.removeItem('karma_tg_auth');
    },
    setIsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
  },
  actions: {
    async init({ commit, dispatch }) {
      try {
        let isLoggedIn = false;
        try {
          isLoggedIn = !!localStorage.getItem('karma_tg_auth');
        } catch (e) {
          console.error('Error accessing localStorage:', e);
        }
        commit('setIsLoggedIn', isLoggedIn);

        if (isLoggedIn) {
          await dispatch('getCurrentUser');
        }
      } catch (e) {
        commit('setLoggedOut');
        handleError(e);
      }
    },
    async login({ commit, dispatch }, user) {
      try {
        const resp = await api.post('/login', user);
        localStorage.setItem('karma_tg_auth', resp.data.token);
        commit('setIsLoggedIn', !!resp.data.token);
        await dispatch('getCurrentUser');
        document.cookie = `${ONBOARDING_COOKIE}=yes; max-age=604800; path=/`;
      } catch (e) {
        handleError(e);
      }
    },
    async logout({ commit }) {
      commit('setLoggedOut');
    },
    async getCurrentUser({ commit }) {
      try {
        const { data } = await api.get('/users/me');
        commit('setCurrentUser', data.user);
      } catch (e) {
        handleError(e);
      }
    },
  },
  getters: {},
  namespaced: true,
};
