<template>
  <div class="language-switcher">
    <button class="language-switcher__button" @click="toggleMenu()">
      {{locale==='en' ? 'EN' : 'RU'}}
    </button>
    <div
      class="language-switcher__menu"
      :class="{'language-switcher__menu--opened': isOpen}"
    >
      <Text weight="600" class="language-switcher__header">
        {{$t('locale_menu.choose_language')}}
      </Text>
      <div class="language-switcher__items">
        <button class="language-switcher__item" @click="switchLocale('en')">
          <div class="language-switcher__language">
            <img :src="en" alt="EN" />
              <Text>
                English
              </Text>
          </div>
          <img
            class="language-switcher__checkmark"
            :class="{'language-switcher__checkmark--checked': locale==='en'}"
            :src="check"
            alt="check"
          />
        </button>        
        <button class="language-switcher__item" @click="switchLocale('ru')">
          <div class="language-switcher__language">
            <img :src="ru" alt="RU" />
            <Text>
              Русский
            </Text>
          </div>
          <img
            class="language-switcher__checkmark"
            :class="{'language-switcher__checkmark--checked': locale==='ru'}"
            :src="check"
            alt="check"
          />
        </button>        
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { Text } from '@sliday/karma-ui-library';
import check from '@/assets/check.svg';
import en from '@/assets/us-flag.svg';
import ru from '@/assets/ru-flag.svg';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();

const isOpen = ref(false);

const toggleMenu = () => {
  isOpen.value = !isOpen.value;

  if (isOpen.value) {
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);
  } else {
    document.removeEventListener('click', handleOutsideClick);
    document.removeEventListener('touchstart', handleOutsideClick);
  }
};

const handleOutsideClick = (e) => {
  if (!e.target.closest('.language-switcher')) {
    toggleMenu();
  }
};

const switchLocale = (newLocale) => {
  locale.value = newLocale;
  localStorage.setItem('karma_tg_locale', newLocale);
  toggleMenu();
}
</script>

<style scoped lang="scss">
.language-switcher {
  position: relative;

  &__button {
    background-color: white;
    border-radius: 5px;
    padding: 8px 10px;
    cursor: pointer;
  }

  &__menu {
    display: none;
    transition: opacity 0.3s;
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    background-color: white;
    border: 1px solid $gray;
    border-radius: 4px;
    padding: 4px 0;
    box-shadow: 8px 8px 32px 0px rgba(0, 0, 0, 0.15);

    &--opened {
      display: block;
    }
  }

  &__header {
    white-space: nowrap;
    padding: 12px 16px;
    border: 1px none $gray;
    border-style: none none solid;
  }

  &__items {
    display: flex;
    flex-direction: column;
  }

  &__item {
    background-color: white;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: $gray;
    }
  }

  &__language {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  &__checkmark {
    display: none;

    &--checked {
      display: block;
    }
  }
}
</style>
